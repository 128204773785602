import request from '@/router/axios';
import { Console } from "console";
var textileBaseUrl = "http://localhost:5202/";
// 引入使用    import { TextilePurchaseOrderIList, TextilePurchaseOrderIById,TextilePurchaseOrderIAdd, TextilePurchaseOrderIEdit, TextilePurchaseOrderIDel} from "@/api/Purchase/TextilePurchaseOrderApi";
//显示列表查询
export const listInfo = (DocumentDate, DocumentNo, DocumentType, Warehouse, InoutStockType, page, pageSize) => {
    return request({
        url: textileBaseUrl + "st/Stock/GetStockInouts",
        method: "get",
        params: {
            "documentDate": DocumentDate,
            "documentNo": DocumentNo,
            "documentType": DocumentType,
            "warehouse": Warehouse,
            "inoutStockType": InoutStockType,
            "page": page,
            "pageSzie": pageSize
        }
    })
}

//根据documentNo获取单条信息
export const getInfoByDocumentNo = (documentNo) => {
    return request({
        url: textileBaseUrl + "st/Stock/GetStockInoutByDocumentNo",
        method: "get",
        params: {
            documentNo
        }
    })
}

//添加
export const addInfo = (row) => {
 console.log("开始",row,textileBaseUrl);
    return request({
        url: textileBaseUrl + "st/Stock/PostStockInout",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const editInfo = (row) => {
    return request({
        url: textileBaseUrl + "st/Stock/PutStockInout",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const deleteInfo = (documentNo) => {
    return request({
        url: textileBaseUrl + "st/Stock/DeleteStockInout",
        method: "delete",
        params: {
            "documentNo": documentNo,
        }
    })
}



